/* eslint-disable prefer-const */
import { Container } from '@chakra-ui/react'

const QuizPage = () => {
  const loadJebbit = () => {
    function embedJebbit(t: string) {
      function e(t: string) {
        let e =
          'attach' === t ? window.addEventListener : window.removeEventListener
        e('DOMContentLoaded', i, !1),
          e('load', i, !1),
          e('scroll', i, !1),
          e('resize', i, !1)
      }
      let n = document.querySelector(t)
      function i() {
        (function (t) {
          let e = t.getBoundingClientRect(),
            n = t.clientHeight / 2,
            i = t.clientWidth / 2
          return (
            e.top >= 0 &&
            e.left >= 0 &&
            e.top <=
              (window.innerHeight || document.documentElement.clientHeight) -
                n &&
            e.left <=
              (window.innerWidth || document.documentElement.clientWidth) - i
          )
        })(n) && n.contentWindow.postMessage('startJebbitCampaign', '*')
      }
      window.addEventListener(
        'message',
        function (t) {
          t &&
            ('jebbitSessionCreated' === t.data
              ? e('remove')
              : 'jebbitCampaignLoaded' === t.data
              ? i()
              : t.data &&
                'redirect' === t.data.action &&
                t.data.options &&
                t.data.options.link &&
                (window.location.href = t.data.options.link))
        },
        !1
      ),
        e('attach')
    }
    embedJebbit('.jebbit-iframe')
  }

  return (
    <Container maxW="container.full" py={0} px={0}>
      <iframe
        className={'jebbit-iframe'}
        src={
          'https://hello-sunshine.jebbit.com/synl24js?L=Owned+Web&deferred=true'
        }
        seamless={true}
        frameBorder={'0'}
        allowFullScreen
        width={'100%'}
        height={'800'}
        onLoad={loadJebbit}
      ></iframe>
    </Container>
  )
}

export default QuizPage
